.header{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.sportHeader{
    text-align: center;
}

.logout{
    border-radius: 5px;
    height: 35px;
    width: 130px;
    font-size: 25px;
}

.sportVideos{
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
}
.sportVideos::-webkit-scrollbar{
    display: none;
}

.eventContainer{
    height: 200px;
    width: 355px;
    background: black;
    margin: 20px;
}

.eventInfo{
    text-align: center;
    font-size: 16px;
}

.imagewrap{
    text-align: center;
}

.waiting{
    height: 200px;
}